export enum COMPANY_TAB {
    'Checklist',
    'Cardápio',
    'Pesquisa',
    'Placa'
}

export const companyTabs = [
    COMPANY_TAB[0],
    COMPANY_TAB[1],
    COMPANY_TAB[2],
    COMPANY_TAB[3]
];
